import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image"];

  connect() {
    // Check if there's an image inside the target element
    const hasImage = this.hasImageTarget;
    if (!hasImage) {
      console.error("No image found within the target element");
      return;
    }

    // Call the function to set the background
    this.setComplementaryGradientBackground();
  }

  setComplementaryGradientBackground() {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = this.imageTarget;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

      const getAverageColor = (data) => {
        let red = 0;
        let green = 0;
        let blue = 0;
        const numPixels = data.length / 4;

        for (let i = 0; i < data.length; i += 4) {
          red += data[i];
          green += data[i + 1];
          blue += data[i + 2];
        }

        return {
          red: Math.round(red / numPixels),
          green: Math.round(green / numPixels),
          blue: Math.round(blue / numPixels),
        };
      };

      const averageColor = getAverageColor(imageData);

      const getComplementaryColor = (color) => {
        return {
          red: Math.round(255 - (color.red * 0.7)),
          green: Math.round(255 - (color.green * 0.7)),
          blue: Math.round(255 - (color.blue * 0.7)),
        };
      };

      const complementaryColor = getComplementaryColor(averageColor);

      const gradientDirection = "to bottom";
      this.element.style.backgroundImage = `linear-gradient(${gradientDirection}, ${`rgba(${complementaryColor.red}, ${complementaryColor.green}, ${complementaryColor.blue}, 0.8)`} 0%, ${`rgba(${complementaryColor.red}, ${complementaryColor.green}, ${complementaryColor.blue}, 0.5)`} 100%)`;
    };

    image.onerror = () => {
      console.error("Error loading image");
    };
  }
}
