var formAction = "https://docs.google.com/forms/d/e/1FAIpQLSc7Cv6uMSGhXquV8sKmKC5Fk2rP79HpxoplN1ViFSwVtYsJgA/formResponse?";
$( "#contact_form #send-email" ).hover(function() {
      $("#contact_form").attr('action', formAction);
    });

function validateEmail($email) {
  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  return emailReg.test( $email );
}

$('#contact_form #send-email').on('click', function(e) {
  event.preventDefault();
  var emailField = $('#contact_form .email').val();

    if( !emailField || !validateEmail(emailField) ) { 
        alert("Sorry, your email is invalid");
    } else {
        if(!$(".contact-title").val()) {	
            $("#contact_form").submit();
            $('.success').removeClass('hidden');
            $('#contact_form input[type=text]').val("");
        } else {
          alert("Sorry, A problem has occured.");
        }
    }
});