import { Controller } from "@hotwired/stimulus"
import { initializeApp } from "firebase/app"
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth"

export default class extends Controller {
  static targets = [ 
    'email', 
    'password', 
    'output' 
  ]

  connect() {
    const firebaseConfig = {
      apiKey: "AIzaSyAD_BiTSbY6pzxHpuQ8S3ThL70exUFMKno",
      authDomain: "clubcarmel-ece80.firebaseapp.com",
      projectId: "clubcarmel-ece80",
      storageBucket: "clubcarmel-ece80.appspot.com",
      messagingSenderId: "518595011011",
      appId: "1:518595011011:web:f3f4f01ef28c581b9e5d76"
    }

    this.app = initializeApp(firebaseConfig)
    this.auth = getAuth(this.app)
    console.log("Auth controller connected!")
    console.log(this.outputTarget)
    this.displayUserInfo()
  }

  signup(event) {
    event.preventDefault()
    const email = this.emailTarget.value
    const password = this.passwordTarget.value

    createUserWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        sessionStorage.setItem('user_id', user.uid)
        sessionStorage.setItem('email', email)
        this.displayUserInfo()
      })
      .catch((error) => {
        console.error('Error:', error)
        this.outputTarget.innerHTML = `Error: ${error.message}`
      })
  }

  login(event) {
    event.preventDefault()
    const email = this.emailTarget.value
    const password = this.passwordTarget.value

    signInWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        sessionStorage.setItem('user_id', user.uid)
        sessionStorage.setItem('email', email)
        this.displayUserInfo()
      })
      .catch((error) => {
        console.error('Error:', error)
        this.outputTarget.innerHTML = `Error: ${error.message}`
      })
  }

  logout() {
    signOut(this.auth)
      .then(() => {
        sessionStorage.removeItem('user_id')
        sessionStorage.removeItem('email')
        this.outputTarget.innerHTML = 'Logged out'
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  displayUserInfo() {
    const userId = sessionStorage.getItem('user_id')
    const email = sessionStorage.getItem('email')
    if (userId && email) {
      this.outputTarget.innerHTML = `Email: ${email}<br>User ID: ${userId}`
    }
  }
}